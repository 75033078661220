import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { MapPin, Moon } from "react-feather";
import { dateFromNow } from "../../utils/staticMethods";
import moment from "moment-timezone";

const OffNetwork = () => {

  const [resultCount, setResultCount] = useState(0);
  const {user} = useAuth()
  const curTime = useRef(moment())

  const columns = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      disableSortBy: true
    },
    {
      Header: "Name (Ref)",
      accessor: "compoundName",
      type: "link",
      link: "/assets/edit",
      stateId: "id"
    },
    {
      Header: "Last Seen",
      accessor: "lastSeen",
      Cell: (cell) => {
        if (cell.value && cell.value.indexOf("0001-01-01") === -1) {
          return <><MapPin width={14} height={14} className="me-1" />{curTime.current ? dateFromNow(cell.value, curTime.current) : dateFromNow(cell.value)}</>;
        }
        return <span><Moon width={14} height={14} className="me-1" /> Waiting...</span>;
      }
    },
    {
      Header: "Customer",
      accessor: "customerName",
      show: user?.role === "Root",
      disableSortBy: true,
      Cell: cell => (<NavLink
        to={{ pathname: `/customers/edit`, search: `?id=${cell.row?.original?.customerId}` }}>{cell.value}</NavLink>)
    },
    {
      Header: "Hashtags",
      accessor: "hashTags",
      disableSortBy: true,
      Cell: cell => (<div>
        {cell.value && cell.value.map((tag, i) =>  <NavLink key={tag} to={{pathname: '/globalSearch', search: `?searchString=${encodeURIComponent('#' + tag)}`}}><span key={i} className='badge bg-primary me-1 my-1 cursor-pointer'>{tag}</span></NavLink>)}
      </div>)
    }
  ];


  return  <React.Fragment>
    <Helmet defer={false}  title={`Outside of Network`} />
    <Container fluid className="p-0">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1 className="h3 mb-0">Outside of Network {typeof(resultCount) === 'string' && `(${resultCount})`}</h1>
      </div>
      <Card className="bg-white p-3">
        <CommonTable setCountParent={setResultCount} secFilterId={'childHashtag'} secId={'cage'} apiName={'reports/offnetwork'} columns={columns} showExport={true}/>
      </Card>
    </Container>
  </React.Fragment>
}
export default OffNetwork