export const globalConfig = {
  apiKey: process.env.NODE_ENV === 'development' ? window.BASE_URL_DEV : window.BASE_URL_PRO,
  googleMapKey: process.env.NODE_ENV === 'development' ? window.REACT_APP_GOOGLE_API_KEY_DEV : window.REACT_APP_GOOGLE_API_KEY_PRO,
  weatherApiKey: process.env.NODE_ENV === 'development' ? window.REACT_APP_WEATHER_API_KEY_DEV : window.REACT_APP_WEATHER_API_KEY_PRO,
  // widgets list
  widgetsConfig: [{
    name: 'AverageBatteryLifespan',
    label: 'Average Battery Lifecycle',
    path: './components/widgets/AverageBatteryLifespan',
    src: 'lifecycle.png',
  }, {
    name: 'AssetSummary',
    label: 'Asset Summary',
    path: './components/widgets/Summary',
    src: 'assetSummary.png',
  },{
    name: 'BatteryLevel',
    label: 'Asset Battery Level',
    path: './components/widgets/BatteryLevel',
    src: 'batteryLevel.png',
  },{
    name: 'BatteryExchanges',
    label: 'Asset Battery Exchanges',
    path: './components/widgets/BatteryExchanges',
    src: 'batteryExchange.png',
  },{
    name: 'CagesByDepot',
    label: 'Cages By Depot',
    path: './components/widgets/CagesByDepot',
    src: 'cages.png',
  },{
    name: 'CagesByCustomer',
    label: 'Cages By Customer',
    path: './components/widgets/CagesByCustomer',
    src: 'cages.png',
  },{
    name: 'CagesDistribution',
    label: 'Cages Distribution',
    path: './components/widgets/CageDistribution',
    src: 'distribution.png',
  },{
    name: 'CagesInTransit',
    label: 'Cages In Transit',
    path: './components/widgets/CagesInTransit',
    src: 'cages.png',
  },{
    name: 'Deployment',
    label: 'Deployment',
    path: './components/widgets/Deployment',
    src: 'deployment.png',
  },{
    label: 'Favourite Assets',
    name: 'FavouriteAssets',
    path: './components/widgets/Favourites',
    src: 'favouriteAssets.png',
  },{
    label: 'Favourite People',
    name: 'FavouritePeople',
    path: './components/widgets/Favourites',
    src: 'favouritePeople.png',
  },{
    name: 'FavouriteZones',
    label: 'Favourite Zones',
    path: './components/widgets/Favourites',
    src: 'favouriteZone.png',
  },{
    label: 'Mini Map',
    name: 'MiniMap',
    path: './components/widgets/MiniMap',
    src: 'map.png',
  },{
    label: 'Mini Floorplan',
    name: 'MiniFloorplan',
    path: './components/widgets/MiniFloorplan',
    src: 'floorplan.png',
  },{
    name: 'PeopleSummary',
    label: 'People Summary',
    path: './components/widgets/Summary',
    src: 'peopleSummary.png',
  },{
    label: 'Recent Items',
    name: 'RecentItems',
    path: './components/widgets/RecentItems',
    src: 'recentItem.png',
  }, {
    name: 'ZoneSummary',
    label: 'Zones Summary',
    path: './components/widgets/Summary',
    src: 'zoneSummary.png',
  }],
  // assets widgets list
  assetWidgets: [
    {
      label: 'Asset Battery Lifecycle',
      name: 'AssetAverageBatteryLifespan',
      path: './components/statistics/AssetAverageBatteryLifespan',
      src: 'battery.png',
    },{
      name: 'AssetBatteryExchanges',
      label: 'Asset Battery Exchanges',
      path: './components/statistics/AssetBatteryExchanges',
      src: 'barChart.png',
    },{
      name: 'AssetUtilisation',
      label: 'Asset Utilisation Hours',
      path: './components/statistics/AssetUtilisation',
      src: 'hours.png',
    },{
      name: 'AssetBatteryVoltage',
      label: 'Asset Battery Voltage',
      path: './components/statistics/AssetBatteryVoltage',
      src: 'lineChart.png',
    },{
      name: 'AssetBatteryLevel',
      label: 'Asset Battery Level',
      path: 'components/statistics/AssetBatteryLevel',
      src: 'guage.png',
    }
  ]
};

